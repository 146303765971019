import { useState } from "react";
import { routeService } from '../services'
import { Table, Button, Modal, ModalActions, ModalContent, ModalDescription, ModalHeader } from 'semantic-ui-react';
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { RequestPayload } from "../services/ApiClient/types";
import { RouteStopDetailsViewModel } from "../services/RoutesService/types";
import { formatAddress } from "../utils/addressHelper";
import { formatDateAndTime } from "../utils/datehelper";

export default function StopDetails(props: any) {
  const { stopId } = props;
  const [modal, setModal] = useState(false);
  const [stopInfo, setStopInfo] = useState<RouteStopDetailsViewModel>(null)
  const [open, setOpen] = React.useState(false)
  const [imageURL, setImageURL] = useState('')

  const openModal = (url: string) => {
    setOpen(true)
    setImageURL(url)
  }

  const toggleModal = () => {
    if (!modal) {
      getStopData(stopId)
    }
    setModal(!modal);
  };

  const style = {
    headerCell: {
      fontSize: '1rem',
      textAlign: 'left',
      whiteSpace: 'pre-line'
    },
    tCell: {
      fontSize: '1rem',
      textAlign: 'left',
    }
  }
  // Stop ID Data

  const getStopData = (stopId: number) => {
    let payload: RequestPayload<null> = { queryParams: { stopId }, payload: null };
    routeService.GetStopDetails(payload)
      .then(response => {
        if (response.success) {
          setStopInfo(response.result)
        }
        else {
          console.log("API call was not successful");
        }
      })
  }

  //print 

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'stopId-data',
    onAfterPrint: () => alert('print success')
  });

  return (
    <>
      <Button onClick={toggleModal} id="openButton">
        {stopId}
      </Button>
      {stopInfo !== null ?
        <Modal ref={componentRef}
          closeIcon
          centered={true}
          size='small'
          onClose={() => { toggleModal() }}
          onOpen={() => { toggleModal() }}
          open={modal}
        >
          <Modal.Header>Route Stop Details<Button attached='top' floated='right' onClick={() => handlePrint} primary id="printButton">Print/Save</Button></Modal.Header>

          <Modal.Content>
            <Modal.Description>
              <Table>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell style={style.headerCell}>Route Id </Table.Cell>
                    <Table.Cell style={style.tCell}>{stopInfo.routeId}</Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell style={style.headerCell}>Address</Table.Cell>
                    <Table.Cell style={style.tCell}>{formatAddress(stopInfo.stop.location, true)}
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell style={style.headerCell}>Reference </Table.Cell>
                    <Table.Cell style={style.tCell}>{stopInfo.stop.reference}</Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell style={style.headerCell}>Pieces </Table.Cell>
                    <Table.Cell style={style.tCell}>{stopInfo.stop.pieces}</Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell style={style.headerCell}>Weight </Table.Cell>
                    <Table.Cell style={style.tCell}>{stopInfo.stop.weight}</Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell style={style.headerCell}>Completed Time </Table.Cell>
                    <Table.Cell style={style.tCell}>{formatDateAndTime(stopInfo.stop.timeCompleted)}</Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell style={style.headerCell}>POD Name </Table.Cell>
                    <Table.Cell style={style.tCell}>{stopInfo.stop.podText}</Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell style={style.headerCell}>POD Signature </Table.Cell>
                    <Table.Cell style={style.tCell}>{stopInfo.stop.podSignatureUrl !== "" ? <img onClick={() => openModal(stopInfo.stop.podSignatureUrl)} width={"200rem"} src={stopInfo.stop.podSignatureUrl} alt={""} /> : "N/A"}</Table.Cell>
                    <Table.Cell style={style.tCell}>{stopInfo.stop.podSignatureUrl !== "" ?
                      <div style={{ display: "flex" }}>
                        {/* <img src={`data:image/svg+xml;base64,${stopInfo.stop.podSignatureUrl}`}
                          width={"200"}
                          height={"auto"}
                          alt={"Encoded POD Signature"}
                        /> */}
                        {/* <img src={`data:image/svg+xml;base64,${stopInfo.stop.podSignatureUrl}`}
                          width={"200"}
                          height={"auto"}
                          alt={"Encoded POD Signature"}
                        /> */}
                      </div>
                      : "N/A"}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Modal.Description>
          </Modal.Content>

        </Modal>

        : ""
      }

      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
      >
        <ModalContent image>
          <ModalDescription>
            <img width={"600rem"} src={imageURL} alt={""} />
          </ModalDescription>
        </ModalContent>
      </Modal>
    </>
  );
}