import React, { CSSProperties } from 'react'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { Button, Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Checkbox, Modal, ModalActions, ModalContent } from 'semantic-ui-react';
import { RequestPayload } from '../services/ApiClient/types';
import "../styles/RouteStopTemplate.scss"
import { routeStopTemplateService } from '../services';
import { RouteStopTemplate as IRouteStopTemplate } from '../services/RouteStopTemplateService/types';
import ReactDatePicker from 'react-datepicker'
import { useUser } from '../components/AppProvider';

const RouteStopTemplate = () => {
    const { user } = useUser();
    const navigate = useNavigate();
    const formatTimeOnly = (dateTimeStr: Date) => {
        const date = new Date(dateTimeStr);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `1900-01-01T${hours}:${minutes}:${seconds}`;
    }
    const formatDateTime = (dateTimeStr: Date) => {
        const date = new Date(dateTimeStr);
        const year = date.getFullYear().toString();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }
    const defaultEntry: IRouteStopTemplate = {
        RouteOrDriverName: '',
        Sequence: 0,
        TimeMin: formatTimeOnly(new Date()),
        TimePreferred: formatTimeOnly(new Date(new Date().setHours(new Date().getHours() + 1))), // No Change
        TimeMax: formatTimeOnly(new Date(new Date().setHours(new Date().getHours() + 2))),
        LocationName: '',
        LocationAddress: '',
        LocationCity: '',
        LocationZIP: '',
        LocationState: '',
        Remarks: null,
        NextScheduledCreation: formatDateTime(new Date()), // No Change
        Sched_Monday: false,
        Sched_Tuesday: false,
        Sched_Wednesday: false,
        Sched_Thursday: false,
        Sched_Friday: false,
        Sched_Saturday: false,
        Sched_Sunday: false,
        UpdatedBy: 'userName', // No Change
        UpdatedWhen: formatDateTime(new Date()), // No Change error when no null
        CreatedBy: '', // No Change
        CreatedWhen: formatDateTime(new Date()), // No Change
        WillCallActive: false
    }
    const noChange: string[] = [
        'id', 'timePreferred', 'TimePreferred', 'nextScheduledCreation', 'NextScheduledCreation', 'updatedBy', 'UpdatedBy', 'updatedWhen', 'UpdatedWhen', 'createdBy', 'CreatedBy', 'createdWhen', 'CreatedWhen'
    ]
    const [show, setShow] = useState(false);
    const [createModalShow, setCreateModalShow] = useState(false)
    const [deleteModalShow, setDeleteModalShow] = useState(false)
    const [selectedEntry, setSelectedEntry] = useState<IRouteStopTemplate>(null)
    const [createEntry, setCreateEntry] = useState<IRouteStopTemplate>(defaultEntry)
    const [tableApi, setTableApi] = useState(null)
    const [testCreation, setTestCreation] = useState(true)
    const [loading, setLoading] = useState(true)
    const [saveButton, setSaveButton] = useState(false)
    const [modalSelect, setModalSelect] = useState(null)

    const handleEdit = (e: any, selectedEntry: IRouteStopTemplate) => {
        const { name } = e.target;
        setSelectedEntry({ ...selectedEntry, [name]: e.target.value })
        setSaveButton(true)
    }
    const handleCreateEdit = (e: any, createEntry: IRouteStopTemplate) => {
        const { name } = e.target;
        setCreateEntry({ ...createEntry, [name]: e.target.value })
    }
    const handleCancel = () => {
        setShow(false)
        setModalSelect(null)
        setSaveButton(false)
        toast.error('Edit Canceled')
    }
    const handleCreateCancel = () => {
        setCreateModalShow(false)
        setModalSelect(null)
        setCreateEntry(defaultEntry)
        toast.error('Creation Cancelled')
    }
    const handleSave = () => {
        setShow(false)
        setModalSelect(null)
        setSaveButton(false)
        toast.success('Changes saved')
        updateEntry(selectedEntry)

    }
    const handleCreateSave = () => {
        setCreateModalShow(false)
        setModalSelect(null)
        toast.success('Creation saved')
        addEntry(createEntry)
    }
    const handleDeleteModal = () => {
        setShow(false)
        setDeleteModalShow(true)
        setModalSelect(3)
    }
    const handleDelete = () => {
        setDeleteModalShow(false)
        setModalSelect(null)
        toast.success('Route Deleted')
        const routeStopTemplateId = selectedEntry.id
        deleteEntry(routeStopTemplateId)

    }
    const handleSelectedRow = (entry: IRouteStopTemplate) => {
        setModalSelect(1)
        setSelectedEntry(entry)
        setShow(true)

    }
    const handleCreation = (createEntry: IRouteStopTemplate) => {
        setCreateModalShow(true)
        setModalSelect(2)
        setCreateEntry(createEntry)
    }
    const formatTime = (dateTimeStr: string): string => {
        let dateTime = new Date(dateTimeStr);

        let hours = dateTime.getHours();
        let period = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'

        let formattedTime = hours.toString().padStart(2, '0') + ':' +
            dateTime.getMinutes().toString().padStart(2, '0') + ':' +
            dateTime.getSeconds().toString().padStart(2, '0') + ' ' +
            period;

        return formattedTime;
    };
    const camelCaseToWords = (camelCaseString: string) => {
        // Split camel case string into words
        const words = camelCaseString.replace(/([a-z])([A-Z])/g, '$1 $2').split(/[_\s]/);

        // Capitalize the first letter of each word
        const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

        // Join capitalized words with spaces
        return capitalizedWords.join(' ');
    }
    const moveIdToLast = (arr: IRouteStopTemplate[]) => {
        return arr.map((obj: IRouteStopTemplate) => {
            const { id, ...rest } = obj; // Destructure 'id' and other keys
            return { ...rest, id }; // Place 'id' at the end
        });
    };
    const getList = async () => {
        let payload: RequestPayload<number> = { payload: localStorage.courierId };
        await routeStopTemplateService.GetRouteStopTemplateById(payload)
            .then(response => {
                setLoading(false);
                setTableApi(moveIdToLast(response.result));
            })
            .catch(err => {
                setLoading(false);
                console.error("ERROR: ", err);
            })

    }
    const updateEntry = async (selectedEntry: IRouteStopTemplate) => {
        let payload: RequestPayload<IRouteStopTemplate> = { payload: selectedEntry };
        await routeStopTemplateService.UpdateRouteStopTemplate(payload)
            .then(response => {
                if (response.success) {
                    getList()
                }
            })
            .catch(error => {
                console.error("Error: ", error);
            })
    }
    const deleteEntry = async (routeTemplateId: number) => {
        let payload: RequestPayload<number> = { payload: routeTemplateId };
        await routeStopTemplateService.DeleteRouteStopTemplateById(payload)
            .then(response => {
                if (response.success) {
                    getList()
                }
            })
            .catch(error => {
                console.error("Error: ", error);
            })
    }
    const addEntry = async (createEntry: IRouteStopTemplate) => {
        createEntry.z_FG_CourierID = user.courierId;
        let payload: RequestPayload<IRouteStopTemplate> = { payload: createEntry };
        await routeStopTemplateService.CreateRouteStopTemplate(payload)
            .then(response => {
                if (response.success) {
                    getList()
                }
            })
            .catch(error => {
                console.error("Error: ", error);
            })
    }
    const formatWeekday = (str: string) => {
        const underscoreIndex = str.indexOf('_');
        if (underscoreIndex !== -1 && underscoreIndex < str.length - 1) {
            return str.substring(underscoreIndex + 1, underscoreIndex + 4);
        }
        return ''; // Return an empty string if there's no underscore or no letters after it
    }

    const headerStyle: CSSProperties = {
        textAlign: 'center',
        padding: '1rem 0rem',
        backgroundColor: '#2d8fc4',
        color: '#fff',
        fontSize: '.8rem'
    }
    const tdStyle: CSSProperties = {
        textAlign: 'center',
        padding: '1rem',
        fontSize: '.8rem'
    }

    useEffect(() => {
        let hasEmptyField = false;
        for (const [key, value] of Object.entries(createEntry)) {
            if (!noChange.includes(key) && typeof value !== 'boolean' && key !== "Remarks") {
                if (value === '') {
                    hasEmptyField = true;
                    break;
                }
            }
        }
        setTestCreation(hasEmptyField);
        // eslint-disable-next-line
    }, [createEntry])

    useEffect(() => {
        if (localStorage.token === null || localStorage.token === undefined) {
            const navTo = `/`
            navigate(navTo)
        } else if (localStorage.courierId === "null") {
            navigate('/Routed')
        } else {
            getList();
            setLoading(true);
        }
        localStorage.setItem('route', "RouteStopTemplates")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate]);

    const colSpan = 1

    return (
        <>
            {loading ?
                <h1>LOADING...</h1>
                :
                tableApi !== null && Array.isArray(tableApi) && tableApi.length > 0 ?
                    <div style={{ width: '95vw' }}>
                        <Button style={{ position: 'absolute', right: '1.5rem', marginTop: '.5rem' }} onClick={() => handleCreation(createEntry)}>Add New</Button>
                        <ToastContainer />
                        <Table style={{ marginLeft: "2%" }} striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    {tableApi !== null && Object.keys(tableApi[0]).map((header: string, idx: number) => {
                                        if (header === 'id') {
                                            return <th key={idx} style={headerStyle} colSpan={colSpan}>{"FG ID"}</th>
                                        } else if (header === 'routeOrDriverName') {
                                            return <th key={idx} style={headerStyle} colSpan={colSpan}>{camelCaseToWords(header.split("").splice(7).join(""))}</th>
                                        } else if (header === 'timePreferred') {
                                            return <th key={idx} style={headerStyle} colSpan={colSpan}>{camelCaseToWords('time pref.')}</th>
                                        } else if (header === 'nextScheduledCreation') {
                                            return <th key={idx} style={headerStyle} colSpan={colSpan}>{'Nxt Sched Creation'}</th>
                                        } else if (header.includes('sched')) {
                                            return <th key={idx} style={headerStyle} colSpan={colSpan}>{formatWeekday(header)}</th>
                                        } else if (header.includes('location')) {
                                            return <th key={idx} style={headerStyle} colSpan={colSpan}>{camelCaseToWords(header.split("").splice(8).join(""))}</th>
                                        } else {
                                            return <th key={idx} style={headerStyle} colSpan={colSpan}>{camelCaseToWords(header)}</th>
                                        }
                                    })}
                                </tr>
                            </thead>
                            <tbody className='row-style'>
                                {tableApi !== null && tableApi.map((entry: IRouteStopTemplate, index: number) => (
                                    <tr key={index} onClick={() => handleSelectedRow(entry)}>
                                        {Object.entries(entry).map(([key, item]: any, idx: number) => {
                                            if (key.includes('Active') || key.includes('sched_')) {
                                                return <td key={idx} id={`${idx}`} style={tdStyle} colSpan={colSpan}><input type='checkbox' disabled checked={item} /></td>
                                            }
                                            else if (key.includes('time')) {
                                                return <td key={idx} id={`${idx}`} style={tdStyle} colSpan={colSpan}>{formatTime(item)}</td>
                                            }
                                            else if (key === 'nextScheduledCreation' || key === 'updatedWhen' || key === 'createdWhen') {
                                                return <td key={idx} id={`${idx}`} style={tdStyle} colSpan={colSpan}>{new Date(item).toDateString().slice(3)}</td>
                                            }
                                            else {
                                                return (
                                                    <td key={idx} id={`${idx}`} style={tdStyle} colSpan={colSpan}>{item}</td>
                                                )
                                            }
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    :
                    <div style={{ width: '95vw' }}>
                        <Button style={{ position: 'absolute', right: '1.5rem', marginTop: '.5rem' }} onClick={() => handleCreation(createEntry)}>Add New</Button>
                        <h1 style={{ marginLeft: '30px' }}>{`No Route Stop Templates for ID: ${user.id ?? user.id | localStorage.courierId}`}</h1>
                    </div>
            }

            <Modal
                size='fullscreen'
                style={{ maxHeight: modalSelect === 3 ? '25vh' : '75vh', margin: '3rem' }}
                centered
                open={modalSelect === 1 ? show && Object.keys(selectedEntry).length > 0 : modalSelect === 2 ? createModalShow : modalSelect === 3 ? deleteModalShow : false
                }
                onClose={modalSelect === 1 ? () => handleCancel : modalSelect === 2 ? () => setCreateModalShow(false) : modalSelect === 3 ? () => setDeleteModalShow(false) : () => handleCancel}
            >
                <Modal.Header closeButton>
                    {modalSelect === 1 ?
                        `Selected Row ID: ${selectedEntry.id}` : modalSelect === 2 ?
                            'New Row Info:' : modalSelect === 3 ?
                                'Are you sure you want to delete this template?' : ''}
                </Modal.Header>
                <ModalContent>
                    {modalSelect === 1 ?
                        <Table responsive striped bordered hover size="sm">
                            <thead>
                                {tableApi !== null && Object.keys(tableApi[0]).map((header: string) =>
                                    <th colSpan={colSpan}>
                                        {/* {header} */}
                                        <input style={{ padding: '1rem', textAlign: 'center', backgroundColor: '#2d8fc4', color: '#fff' }} name={header} defaultValue={camelCaseToWords(header)} />
                                    </th>
                                )}
                            </thead>
                            <tbody>
                                {/* selected rows items */}
                                <tr>
                                    {selectedEntry !== null && Object.entries(selectedEntry).map(([key, value]: [any, any]) => {
                                        if (noChange.includes(key) && key !== 'timePreferred') {
                                            if (key === 'nextScheduledCreation' || key === 'updatedWhen' || key === 'createdWhen') {
                                                return <td style={{ textAlign: 'center' }} colSpan={colSpan}>{new Date(value).toDateString().slice(3)}</td>;
                                            } else {
                                                return <td style={{ textAlign: 'center' }} colSpan={colSpan}>{value}</td>;
                                            }
                                        } else if (key.includes('time')) {
                                            return <td style={{ textAlign: 'center' }} colSpan={colSpan} key={key}>
                                                <ReactDatePicker
                                                    selected={new Date(value)}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption='Time'
                                                    dateFormat="h:mm aa"
                                                    onChange={(date) => { setSelectedEntry({ ...selectedEntry, [key]: formatDateTime(date) }); setSaveButton(true) }} />
                                                {/* onChange={(date) => console.log("How value need to look: ", value, "How Date looks: ", date, "how Selected Looks: ", new Date(formatDateTime(date)))} />   */}
                                            </td>

                                        } else if (key === 'willCallActive' || key.includes('sched_')) {
                                            return (
                                                <td colSpan={colSpan} align='center'>
                                                    <input type='checkbox' checked={value} onChange={() => { setSelectedEntry({ ...selectedEntry, [key]: !value }); setSaveButton(true) }} />
                                                </td>
                                            )
                                        }
                                        else if (key === 'sequence') {
                                            return (
                                                <td style={{ textAlign: 'center' }} colSpan={colSpan} key={key}>
                                                    <Form.Control
                                                        type='number'
                                                        name={key}
                                                        defaultValue={value}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEdit(e, selectedEntry)}
                                                    />
                                                </td>
                                            );

                                        }
                                        else {
                                            return (
                                                <td style={{ textAlign: 'center' }} colSpan={colSpan} key={key}>
                                                    <Form.Control
                                                        type='text'
                                                        name={key}
                                                        defaultValue={value}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEdit(e, selectedEntry)}
                                                    />
                                                </td>
                                            );
                                        }
                                    })}
                                </tr>
                            </tbody>
                        </Table> : modalSelect === 2 ?
                            <Table responsive striped bordered hover size="sm">
                                <thead>
                                    {Object.keys(createEntry).map((header: string) =>
                                        <th style={{ paddingLeft: '3rem', paddingRight: '3rem' }} colSpan={colSpan}>
                                            {/* {header} */}
                                            <input style={{ fontWeight: 'bold', border: 'none', textAlign: 'center' }} name={header} defaultValue={camelCaseToWords(header)} />
                                        </th>
                                    )}
                                </thead>
                                <tbody>

                                    {/* created rows items */}
                                    <tr>
                                        {Object.entries(createEntry).map(([key, value]: [any, any]) => {
                                            if (noChange.includes(key)) {
                                                return <td style={{ textAlign: 'center' }} colSpan={colSpan}>{value}</td>;
                                            } else if (key === 'WillCallActive' || key.includes('Sched_')) {
                                                return (
                                                    <td colSpan={colSpan} align='center'>
                                                        <input type='checkbox' checked={value} onChange={() => setCreateEntry({ ...createEntry, [key]: !value })} />
                                                    </td>
                                                )
                                            }
                                            else if (key === 'Sequence') {
                                                return (
                                                    <td style={{ textAlign: 'center' }} colSpan={colSpan} key={key}>
                                                        <Form.Control
                                                            type='number'
                                                            name={key}
                                                            defaultValue={value}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCreateEdit(e, createEntry)}
                                                        />
                                                    </td>
                                                );
                                            }
                                            else if (key === 'TimeMin') {
                                                return (
                                                    <td style={{ textAlign: 'center' }} colSpan={colSpan} key={key}>
                                                        <ReactDatePicker
                                                            selected={new Date(value)}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeIntervals={15}
                                                            timeCaption='Time'
                                                            dateFormat="h:mm aa"
                                                            onChange={(date) =>
                                                                setCreateEntry({
                                                                    ...createEntry,
                                                                    [key]: formatTimeOnly(date),
                                                                    TimeMax: formatTimeOnly(new Date(new Date(date).setHours(new Date(date).getHours() + 1))),
                                                                    TimePreferred: formatTimeOnly(date)
                                                                })} />
                                                    </td>
                                                )
                                            }
                                            else if (key === 'TimeMax') {
                                                return (
                                                    <td style={{ textAlign: 'center' }} colSpan={colSpan} key={key}>
                                                        <ReactDatePicker
                                                            selected={new Date(value)}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeIntervals={15}
                                                            timeCaption='Time'
                                                            dateFormat="h:mm aa"
                                                            onChange={(date) => setCreateEntry({ ...createEntry, [key]: formatTimeOnly(date) })} />
                                                    </td>
                                                )
                                            }
                                            else {
                                                return (
                                                    <td style={{ textAlign: 'center' }} colSpan={colSpan} key={key}>
                                                        <Form.Control
                                                            type='text'
                                                            name={key}
                                                            defaultValue={value}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCreateEdit(e, createEntry)}
                                                        />
                                                    </td>
                                                );
                                            }
                                        })}
                                    </tr>
                                </tbody>
                            </Table> : modalSelect === 3 ? '(Active) Deleting this cannot be reversed | (inActive) You can deactivate it instead' : ''}
                </ModalContent >
                <ModalActions>
                    {modalSelect === 1 ?
                        (<>
                            <Checkbox label={'Active'} toggle />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button variant="danger" onClick={handleDeleteModal}>
                                Delete
                            </Button>

                            <Button style={{ marginRight: '10px' }} variant={saveButton ? "primary" : "secondary"} onClick={saveButton ? handleSave : handleCancel}>
                                {saveButton ? 'Save Changes' : 'Cancel'}
                            </Button>
                        </>) : modalSelect === 2 ?
                            (<>
                                <Button variant="secondary" onClick={handleCreateCancel}>
                                    Cancel
                                </Button>
                                &nbsp;&nbsp;
                                <Button style={{ marginRight: '10px' }} variant="primary" disabled={testCreation} onClick={handleCreateSave}>
                                    {/* <Button variant="primary" onClick={handleCreateSave}> */}
                                    Create Template
                                </Button>
                            </>) : modalSelect === 3 ?
                                (<>
                                    <Button variant="danger" onClick={handleDelete} >
                                        Confirm Delete
                                    </Button>

                                    <Button variant="primary" onClick={() => { setShow(true); setDeleteModalShow(false) }} >
                                        (active) No | (inActive) Make template inactive instead
                                    </Button>
                                </>) :
                                ''
                    }
                </ModalActions>
            </Modal>
        </>
    )
}


export default RouteStopTemplate