import React, { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Card, Grid, Button, Form, Input, Message, Table, Pagination, Loader } from 'semantic-ui-react';
import DatePicker from "react-datepicker";
import { searchService } from '../services';
import { RequestPayload } from '../services/ApiClient/types';
import { OnDemandSearchResult, RouteStopSearchResult, SearchQueryParams } from '../services/SearchService/types';
import '../styles/AdvanceSearch.scss'
import moment from 'moment';
type Props = {}

const style = {
    headerCell: {
        backgroundColor: "#2d8fc4",
        color: "white",
        border: "0.5px solid #ddd",
        fontSize: '1rem',
        textAlign: 'center',
        whiteSpace: 'pre-line'
    },
    tCell: {
        fontSize: '1rem',
        textAlign: 'center',
    },

}
const defaultRouteStopView: RouteStopSearchResult = {
    id: 0,
    courierName: 'string',
    status: 'string',
    podText: 'string',
    reference: 'string',
    name: 'string',
    address1: 'string',
    address2: 'string',
    city: 'string',
    state: 'string',
    zip: 'string',
    reference1: 'string',
    reference2: 'string',
    postDate: new Date()
}

const routeStopViewHeaders = Object.keys(defaultRouteStopView);

const defaultOnDemandSearchResults: OnDemandSearchResult = {
    fgOrderId: 0,
    courierName: 'string',
    status: 'string',
    trackingNumber: 'string',
    originName: 'string',
    originAddress: 'string',
    originCity: 'string',
    originState: 'string',
    originZip: 'string',
    destName: 'string',
    destAddress: 'string',
    destCity: 'string',
    destState: 'string',
    destZip: 'string',
    destComments: 'string',
    reference1: 'string',
    reference2: 'string',
    userData1: 'string',
    orderDate: new Date()
}

const onDemandViewHeaders = Object.keys(defaultOnDemandSearchResults);
const headerFix = (header: string | number) => {
    if (typeof header === 'number') {
        return header
    } else {
        return `${header.replace(/^./, m => m.toUpperCase())}`;
    };
}



const AdvanceSearch = (props: Props) => {
    const navigate = useNavigate();
    const [messageVisible, setMessageVisible] = useState(false);
    const [searchValue, setSearchValue] = useState(null)
    const [searchDates, setSearchDates] = useState({ fromDate: moment(new Date()).subtract(7, 'days').toDate(), toDate: new Date() })
    const [searchResults, setSearchResults] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null)
    const [currentPage, setCurrentPage] = useState({ stops: 1, onDemand: 1 });
    const [itemsPerPage,] = useState(25);


    useEffect(() => {
        if (localStorage.token === null || localStorage.token === undefined) {
            const navTo = `/`
            navigate(navTo)
        }
        localStorage.setItem('route', "Search")
    }, [])


    const customSearch = () => {
        try {
            let query: SearchQueryParams = {
                searchCriteria: searchValue,
                fromDate: searchDates.fromDate.toDateString(),
                toDate: searchDates.toDate.toDateString(),
            }
            let payload: RequestPayload<null> = {
                queryParams: query, payload: null
            }
            setIsLoading(true)
            searchService.GetTrackingInfo(payload)
                .then(response => {
                    if (response.success) {
                        setSearchResults(response.result)
                        setIsLoading(false)
                        console.log("searchResponse: ", response.result)
                    } else if (response.success === false) {
                        setErrorMsg(response)
                        setIsLoading(false)
                    }
                    setIsLoading(false)
                })
        } catch (err) {
            console.log("A error has occurred:", err)
        }
    }
    const handlePageChange = (e: any, { activePage }: any) => {
        setCurrentPage({ ...activePage });
    }
    let currentPageStopData = searchResults?.routeStopSearchResults?.slice((currentPage.stops - 1) * itemsPerPage, currentPage.stops * itemsPerPage);
    let currentPageOnDemandData = searchResults?.onDemandSearchResults?.slice((currentPage.onDemand - 1) * itemsPerPage, currentPage.onDemand * itemsPerPage);
    // console.log(`Pagination- stops:${currentPage.stops}, onDemand:${currentPage.onDemand}`)



    console.log(localStorage)
    return (
        <div className="search-container">
            <div className='search-wrapper'>
                {!isLoading ? <Card className='search-card'>
                    <Card.Content id="search-content">
                        <Grid>
                            <Grid.Row columns={2} id="search-grid-row">
                                <Grid.Column width={5}>
                                    <Form.Field >
                                        <Input id='search-input' icon='search' placeholder={'Search...'} value={searchValue} onChange={e => setSearchValue(e.target.value)} />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={11}>
                                    <Button disabled={!searchValue} primary color='blue' id="search-button" onClick={() => customSearch()}>
                                        Search
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Card.Content>
                    <Card.Content>
                        {messageVisible ?
                            <Message
                                error
                                header='Not Found'
                                content={'No Route(s) or On Demand Stop(s) were found matching your criteria.'}
                                onDismiss={() => setMessageVisible(false)}
                            /> : ""
                        }
                        <Form>
                            {/* <Form.Field>
                                <span id="tracking-selected">Search Fields: </span>
                            </Form.Field>
                            <Form.Group>
                                <Form.Field>
                                    <Checkbox
                                        toggle
                                        label='Address'
                                        checked={false}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Checkbox
                                        toggle
                                        label='Descriptions/Remarks'
                                        checked={false}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Checkbox
                                        toggle
                                        label='All'
                                        checked={false}
                                    />
                                </Form.Field>
                            </Form.Group> */}
                            <Form.Group>
                                <Form.Field
                                    control={Input}
                                    label='From Date'
                                    placeholder='MM/DD/YYYY'
                                >
                                    <DatePicker
                                        onChange={(date) => setSearchDates({ ...searchDates, fromDate: date, })}
                                        selected={searchDates.fromDate}
                                    />
                                </Form.Field>
                                <Form.Field
                                    control={Input}
                                    label='To Date'
                                    placeholder='MM/DD/YYYY'
                                >
                                    <DatePicker
                                        minDate={searchDates.fromDate}
                                        selected={searchDates.toDate}
                                        onChange={(date) => setSearchDates({ ...searchDates, toDate: date })}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Card.Content>
                </Card>
                    : <Loader size='massive' active={isLoading} />}

                {errorMsg && `Error: ${errorMsg?.message}`}
                {searchResults?.routeStopSearchResults?.length > 0 && !isLoading && <>
                    <h3>Route stops</h3>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                {routeStopViewHeaders.map((header: string | number, idx: number) => {
                                    return (
                                        <Table.HeaderCell style={style.headerCell} key={idx}>{headerFix(header)}</Table.HeaderCell>
                                    )
                                })}
                            </Table.Row>
                        </Table.Header>
                        {currentPageStopData.map((row: string | number, idx: number) => {
                            return (
                                <Table.Row>
                                    {
                                        Object.values(row).map((rowData: string | number, idx: number) => {
                                            return (
                                                <Table.Cell style={style.tCell}>{rowData}</Table.Cell>
                                            )
                                        })
                                    }
                                </Table.Row>
                            )
                        }
                        )
                        }
                    </Table>
                    <Pagination
                        activePage={currentPage.stops}
                        onPageChange={(e, activePage: any) => setCurrentPage({ ...currentPage, stops: activePage.activePage })}
                        siblingRange={1}
                        pointing
                        secondary
                        totalPages={Math.ceil(searchResults?.routeStopSearchResults?.length / itemsPerPage)}
                    />
                </>
                }
                {searchResults?.routeStopSearchResults?.length === 0 ? searchResults?.onDemandSearchResults?.length === 0 ? <><h1>No results found</h1></> : <><h1>No Routes found</h1></> : null}
                {
                    searchResults?.onDemandSearchResults?.length > 0 &&
                    <>
                        <h3>On Demand</h3>
                        <Table>
                            <Table.Header>
                                <Table.Header>
                                    <Table.Row>
                                        {onDemandViewHeaders.map((header: string | number, idx: number) => {
                                            return (
                                                <Table.HeaderCell style={style.headerCell} key={idx}>{header}</Table.HeaderCell>
                                            )
                                        })}
                                    </Table.Row>
                                </Table.Header>
                            </Table.Header>
                            {currentPageOnDemandData.map((row: string | number, idx: number) => {
                                return (
                                    <Table.Row key={idx}>
                                        {
                                            Object.values(row).map((rowData: string | number, idx: number) => {
                                                return (
                                                    <Table.Cell style={style.tCell} key={idx}>{rowData}</Table.Cell>
                                                )
                                            })
                                        }
                                    </Table.Row>
                                )
                            }
                            )
                            }
                        </Table>
                        <Pagination
                            activePage={currentPage.onDemand}
                            onPageChange={(e, activePage: any) => setCurrentPage({ ...currentPage, onDemand: activePage.activePage })}
                            siblingRange={1}
                            pointing
                            secondary
                            totalPages={Math.ceil(searchResults?.routeStopSearchResults?.length / itemsPerPage)}
                        />
                    </>
                }
            </div>
        </div>
    )
}

export default AdvanceSearch