import { apiClient } from '../ApiClient';
import { ApiResponse, RequestPayload } from "../ApiClient/types"
import { RouteStopTemplate, RouteStopTemplateArray } from './types';

class RouteStopTemplateService {
  /*** Returns confirmation of new RouteStopTemplate
   * @param payload  = RequestPayload<RouteStopTemplate> 
   * @example
   * ```ts
   * let payload: RequestPayload<RouteStopTemplate> = { payload: newRouteStopTemplate };
   * ```
   * @returns Success Message
   */
  async CreateRouteStopTemplate(payload: RequestPayload<RouteStopTemplate>): Promise<ApiResponse<RouteStopTemplate>> {
    return apiClient.post<RouteStopTemplate>(
      `/routeStopTemplate`,
      payload
    );
  }

  /*** Returns list of RouteStopTemplate
   * @param payload  = RequestPayload<null> 
   * @example
   * ```ts
   * let payload: RequestPayload<null> = { payload: null };
   * ```
   * @returns RouteStopTemplate[]
   */
  async GetRouteStopTemplateServiceList(payload: RequestPayload<null>): Promise<ApiResponse<RouteStopTemplateArray>> {
    return apiClient.get<RouteStopTemplate[]>(
      '/routeStopTemplate/',
      payload
    );
  }

  /*** Returns single RouteStopTemplate
  * @param payload  = RequestPayload<RouteStopTemplate> 
  * @example
  * ```ts
  * let payload: RequestPayload<number> = { payload: 30016 };
  * ```
  * @returns RouteStopTemplate
  */
  async GetRouteStopTemplateById(payload: RequestPayload<number>): Promise<ApiResponse<RouteStopTemplate[]>> {
    return apiClient.get<RouteStopTemplate[]>(
      `/routeStopTemplate/${payload.payload}`,
      payload
    );
  }

  /*** Returns confirmation that RouteStopTemplate was updated
* @param payload  = RequestPayload<RouteStopTemplate> 
* @example
* ```ts
* let payload: RequestPayload<RouteStopTemplate> = { payload: newRouteStopTemplate };
* ```
* @returns RouteStopTemplate
*/
  async UpdateRouteStopTemplate(payload: RequestPayload<RouteStopTemplate>): Promise<ApiResponse<RouteStopTemplate>> {
    return apiClient.put<RouteStopTemplate>(
      `/routeStopTemplate/`,
      payload,
    );
  }

  /*** Returns confirmation that RouteStopTemplate was deleted
* @param payload  = RequestPayload<number> 
* @example
* ```ts
* let payload: RequestPayload<RouteStopTemplate> = { payload: 32 };
* ```
* @returns RouteStopTemplate
*/
  async DeleteRouteStopTemplateById(payload: RequestPayload<number>): Promise<ApiResponse<string>> {
    return apiClient.delete<string>(
      `/routeStopTemplate/${payload.payload}`,
      payload
    );
  }
}

export { RouteStopTemplateService }